import { Component, forwardRef, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChipComponent, ModalService } from 'addiction-components';
import { ChallengeService } from '../../../pages/contest/challenges/services/challenge.service';
import { TranslateService } from '@ngx-translate/core';
import { ReactiveFormsModule, NG_VALUE_ACCESSOR, NG_VALIDATORS } from '@angular/forms';
import { Observable, filter, switchMap, map } from 'rxjs';
import { GenericSelectorDialogComponent } from 'src/app/shared/components/generic-selector-dialog/generic-selector-dialog.component';
import { Challenge } from 'src/app/shared/models';
import { environment } from 'src/environments/environment';
import { BaseChipsSelectorComponent, ChipValue } from './base-chips-selector.component';
import { format } from 'date-fns';
import { DataleanDirectiveModule } from '../../modules/directive.module';

@Component({
	selector: 'datalean-challenge-chip-selector',
	standalone: true,
	imports: [CommonModule, ChipComponent, ReactiveFormsModule, DataleanDirectiveModule],
	providers: [
		{ provide: NG_VALUE_ACCESSOR, multi: true, useExisting: forwardRef(() => ChallengeChipSelectorComponent) },
		{
			provide: NG_VALIDATORS,
			multi: true,
			useExisting: forwardRef(() => ChallengeChipSelectorComponent),
		},
	],
	templateUrl: './chips-selector.component.html',
	styleUrls: ['./chips-selector.component.scss'],
})
export class ChallengeChipSelectorComponent extends BaseChipsSelectorComponent {
	private modalSrv = inject(ModalService);
	private challengeService = inject(ChallengeService);

	localizationService = inject(TranslateService);

	private isChallenge(obj: Challenge | ChipValue): obj is Challenge {
		return (obj as Challenge).localizedValues !== undefined || (obj as Challenge).limitScore !== undefined;
	}

	constructor() {
		super();
	}

	override writeValue(val: (ChipValue | Challenge)[]): void {
		const chips: ChipValue[] = val.map((challenge) => {
			let name: string | { language: string; value: string }[] | undefined;
			if (challenge.name) {
				name = challenge.name;
			} else if (this.isChallenge(challenge)) {
				name = challenge.localizedValues?.map((value) => ({ language: value.locale, value: value['name'] as string }));
			}
			return { ...challenge, name: name ?? '' };
		});

		super.writeValue(chips);
	}

	override openDialog(currentValues: ChipValue[] = []): Observable<{ selected: ChipValue[]; replace?: boolean | undefined }> {
		// console.log('currentValues', currentValues);
		const selected = (currentValues || []).map(({ uuid }) => uuid);

		return this.modalSrv
			.openDialog(
				GenericSelectorDialogComponent<Challenge>,
				{ title: 'CHALLENGE.SELECT' },
				{
					negativeReflection: false,
					selectedUUIDs: selected,
					endpoint: environment.challengeUrl,
					additionalParams: {
						locale: this.localizationService.currentLang ?? this.localizationService.defaultLang,
					},
					additionalFields: [
						{
							fieldName: 'startDate',
							columnHeader: 'LIST_HEADER.START_DATE',
							cellTemplate: (item: Challenge) => {
								return item.startDate ? `<span>${format(new Date(item.startDate), 'dd-MM-yyyy')}</span>` : ''
							}
						},
						{
							fieldName: 'endDate',
							columnHeader: 'LIST_HEADER.END_DATE',
							cellTemplate: (item: Challenge) => {
								return item.endDate ? `<span>${format(new Date(item.endDate), 'dd-MM-yyyy')}</span>` : ''
							}
						},
					],
				}
			)
			.result$.pipe(
				// controllo che la modal sia stata confermata
				filter((result) => result?.reason === 'COMPLETE' && !!result.data),
				switchMap(({ data }) => this.challengeService.getChallenges({ parts: [], filters: { uuids: data?.selected } })),
				map(({ result }) => ({ selected: result.map((p) => this.mapChallengeToChipValue(p)), replace: true }))
			);
	}

	private mapChallengeToChipValue(challenge: Challenge): ChipValue {
		let name: string = challenge.name ?? '';
		if (challenge.localizedValues && !name) {
			name = challenge.localizedValues.find((value) => value.locale === this.localizationService.currentLang)?.name ?? '';
		}

		return {
			uuid: challenge.uuid,
			name,
		};
	}
}
