<div class="form-field {{ formControl.status | lowercase }}">
	<label *ngIf="label">{{ label }} <span *ngIf="requiredIcon">*</span> <span *ngIf="hint" class="tooltip" [matTooltip]="hint">hint</span></label>
	<div class="form-input-container">
		<textarea
			[name]="name"
			[readOnly]="readonly"
			[placeholder]="placeholder"
			[formControl]="formControl"
			(click)="click.emit($event)"
			(keydown)="keydown.emit($event)"></textarea>
	</div>
</div>
