<addiction-search (search)="filterByName($event)"></addiction-search>

<datalean-table
	*ngIf="tableRows$ | async as tableRows"
  [dataSource]="tableRows"
	[columns]="baseTableConfig.columns"
  [templates]="[t1, t2]"
  [noItemsTemplate]="noItemsTemplateUser"
	[maxCheckable]="limit"
	[selected]="selectedRows$ | async"
	[page]="pageSettings$ | async"
	(pageChange)="pageChanged($event)"
	(selectedChange)="selectionChanged($event)">

  <ng-template #t1 let-element>
    <span>{{ element.uuid }}</span>
  </ng-template>
  <ng-template #t2 let-element>
    <span class="highlight">{{ element.name }}</span>
  </ng-template>

  <div class="single-row-skeleton">
    <div class="single-column single">
      <span></span>
    </div>
    <div class="single-column double">
      <span class="highlight"></span>
    </div>
  </div>

  <ng-template #noItemsTemplateUser>
    <div class="no-results-container">
      <ng-lottie [options]="{ path: './assets/lottie/icn-dam-nocontent.json' }"></ng-lottie>
      <div class="title">
        {{ 'GRID.NO_RESULTS_TITLE' | translate }}
      </div>
      <div class="text">
        {{ 'GRID.NO_RESULTS_TEXT' | translate }}
      </div>
    </div>
  </ng-template>

</datalean-table>

<div class="buttons-container">
	<button (click)="close()">
		<span>{{ 'ADD' | translate }}</span>
	</button>
</div>
