import { CommonModule } from '@angular/common';
import { Component, forwardRef } from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { filter, map, Observable, switchMap } from 'rxjs';
import { ApplicationUser } from '../../models';
import { AppUserService } from '../../services/app-user.service';
import { AppUserSelectorDialogComponent } from '../app-user-selector-dialog/app-user-selector-dialog.component';
import { ModalService } from 'addiction-components';
import { BaseChipsSelectorComponent, ChipValue } from './base-chips-selector.component';
import { ChipComponent } from 'addiction-components';
import { DataleanDirectiveModule } from '../../modules/directive.module';

// ℹ️ Segue la documentazione: https://addiction.atlassian.net/wiki/spaces/DOCTEC/pages/2269315073/ChipsSelector

@Component({
	selector: 'datalean-app-user-chips-selector',
	templateUrl: './chips-selector.component.html',
	styleUrls: ['./chips-selector.component.scss'],
	standalone: true,
	imports: [CommonModule, ChipComponent, ReactiveFormsModule, DataleanDirectiveModule],
	providers: [
		{ provide: NG_VALUE_ACCESSOR, multi: true, useExisting: AppUserChipsSelectorComponent },
		{
			provide: NG_VALIDATORS,
			multi: true,
			useExisting: forwardRef(() => AppUserChipsSelectorComponent),
		},
	],
})
export class AppUserChipsSelectorComponent extends BaseChipsSelectorComponent {
	constructor(private modalSrv: ModalService, private appUserSrv: AppUserService) {
		super();
	}

	override openDialog(currentValues: ChipValue[]): Observable<{ selected: ChipValue[]; replace?: boolean | undefined }> {
		const selected = currentValues.map(({ uuid }) => uuid);

		return this.modalSrv.openDialog(AppUserSelectorDialogComponent, { title: '' }, { selectedUUIDs: selected }).result$.pipe(
			// controllo che la modal sia stata confermata
			filter((result) => result.reason === 'COMPLETE' && !!result.data),
			// recupero le informazioni mancanti
			switchMap(({ data }) => this.appUserSrv.getUsers({ uuid: data?.selected.join(',') || [] })),
			// mappo i prodotti nella struttura del componente
			map(({ result }) => ({ selected: result.map((r) => this.mapRoleToChipValue(r)) }))
		);
	}

	private mapRoleToChipValue(user: ApplicationUser) {
		return {
			uuid: user.uuid,
			name: user.firstName + ' ' + user.lastName,
			firstName: user.firstName,
			lastName: user.lastName,
		};
	}
}
