import { CommonModule } from '@angular/common';
import { Component, Input, forwardRef, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { Store } from '@ngrx/store';
import { ChipComponent, ModalService } from 'addiction-components';
import { Observable, filter, map, switchMap } from 'rxjs';
import { CommunitySelectSelectors } from 'src/app/core/state/app.selectors';
import { LessonsService } from 'src/app/pages/lms/lessons/services/lessons.service';
import { Lesson } from '../../models';
import { LessonsSelectorDialogComponent } from '../lessons-selector-dialog/lessons-selector-dialog.component';
import { BaseChipsSelectorComponent, ChipValue } from './base-chips-selector.component';

// ℹ️ Segue la documentazione: https://addiction.atlassian.net/wiki/spaces/DOCTEC/pages/2269315073/ChipsSelector

@Component({
	selector: 'datalean-lesson-chips-selector',
	templateUrl: './chips-selector.component.html',
	styleUrls: ['./chips-selector.component.scss'],
	standalone: true,
	imports: [CommonModule, ChipComponent, ReactiveFormsModule, LessonsSelectorDialogComponent],
	providers: [
		{ provide: NG_VALUE_ACCESSOR, multi: true, useExisting: forwardRef(() => LessonsChipsSelectorComponent) },
		{
			provide: NG_VALIDATORS,
			multi: true,
			useExisting: forwardRef(() => LessonsChipsSelectorComponent),
		},
	],
})
export class LessonsChipsSelectorComponent extends BaseChipsSelectorComponent {
	@Input() limit?: number;

	private store = inject(Store);

	lastCommunityUUID: string | undefined;

	constructor(private modalSrv: ModalService, private lessonSrv: LessonsService) {
		super();
		this.store
			.select(CommunitySelectSelectors.selectLastCommunitySelectedForApiRest)
			.pipe(takeUntilDestroyed())
			.subscribe((community) => (this.lastCommunityUUID = community));
	}

	override openDialog(currentValues: ChipValue[]): Observable<{ selected: ChipValue[]; replace?: boolean | undefined }> {
		const selected = currentValues.map(({ uuid }) => uuid);

		// console.log('selected', selected);

		return this.modalSrv.openDialog(LessonsSelectorDialogComponent, { title: '' }, { selectedUUIDs: selected, limit: this.limit }).result$.pipe(
			// controllo che la modal sia stata confermata
			filter((result) => result.reason === 'COMPLETE' && !!result.data),
			// recupero le informazioni mancanti
			switchMap(({ data }) => this.lessonSrv.getLessons({ parts: [], filters: { uuids: data?.uuids, communityUUID: this.lastCommunityUUID } })),
			// mappo i prodotti nella struttura del componente
			map(({ result }) => ({ selected: result.map((p) => this.mapLessonToChipValue(p)), replace: !!this.limit }))
		);
	}

	private mapLessonToChipValue(lesson: Lesson): ChipValue {
		if (lesson.localizedValues) {
			return {
				uuid: lesson.uuid,
				name: lesson.localizedValues.map((lv) => ({ language: lv.locale, value: lv.name }))[0].value,
			};
		}
		return {
			uuid: lesson.uuid,
			name: lesson.name,
		};
	}
}
