import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AbstractControl, ControlValueAccessor, ValidationErrors, Validator, Validators } from '@angular/forms';
import { first, Observable } from 'rxjs';

// ℹ️ Segue la documentazione: https://addiction.atlassian.net/wiki/spaces/DOCTEC/pages/2269315073/ChipsSelector

@Component({
	selector: 'datalean-chips-selector',
	template: '',
	styleUrls: [],
})
/** NON utilizzare direttamente quest component (d'altronde è abstract...) */
export abstract class BaseChipsSelectorComponent implements ControlValueAccessor, Validator {
	@Input('value') items?: ChipValue[] = [];
	protected disabled: boolean = false;
	protected skeletonLoaderChipsCounter = 0;

	@Input() emit: 'object' | 'uuid' = 'object';
	@Input() label: string = '';
	@Input() requiredIcon?: boolean = false;
	@Input() readonly: boolean = false;

	@Output() changed = new EventEmitter<ChipValue[] | string[]>();

	protected onChangeFn: (value: ChipValue[] | string[]) => void = () => {};
	protected onTouchedFn: () => void = () => {};

	constructor() {}

	onValidatorChange = () => {};

	registerOnValidatorChange(fn: () => void): void {
		this.onValidatorChange = fn;
	}

	validate(c: AbstractControl): ValidationErrors | null {
		// console.log('validate', c);
		if (!this.requiredIcon && c.hasValidator(Validators.required)) {
			this.requiredIcon = true;
		}
		return c.invalid ? { ...c.errors } : null;
	}

	writeValue(val: ChipValue[]): void {
		this.items = val;
	}

	registerOnChange(fn: (val: ChipValue[] | string[]) => void): void {
		this.onChangeFn = fn;
	}

	registerOnTouched(fn: () => void): void {
		this.onTouchedFn = fn;
	}

	setDisabledState(isDisabled: boolean): void {
		this.disabled = isDisabled;
	}

	protected valueChanged() {
		this.changed.emit(this.getOutputValue());
		this.onChangeFn(this.getOutputValue());
		this.onTouchedFn();
	}
	protected clear() {
		this.items = [];
		this.valueChanged();
	}

	protected addValues(values: ChipValue[]) {
		const newValues = values.filter((val) => !this.items?.some((item) => item.uuid === val.uuid));
		if (this.items) this.items = [...this.items, ...newValues];
		else this.items = newValues;
		this.valueChanged();
	}

	protected removeValues(uuids: string[]) {
		this.items = this.items?.filter(({ uuid }) => !uuids.includes(uuid));
		this.valueChanged();
	}

	protected setValues(values: ChipValue[]) {
		this.items = values;
		this.valueChanged();
	}

	getOutputValue() {
		if (this.emit === 'uuid') return this.items?.map(({ uuid }) => uuid) ?? [];
		return this.items ?? [];
	}

	/** Apre il dialogo di selezione nuovi elementi e deve restituire gli elementi selezionati formattati correttamente */
	abstract openDialog(currentValues: ChipValue[]): Observable<{ selected: ChipValue[]; replace?: boolean } | ChipValue[] | undefined>;

	add() {
		this.openDialog(this.items ?? [])
			.pipe(first())
			.subscribe((result) => {
				if (result) {
					if (!Array.isArray(result)) {
						if (result.replace === true) this.setValues(result.selected);
						else this.addValues(result.selected);
					} else {
						this.setValues(result);
					}
				}
			});
	}

	removeItem(item: ChipValue) {
		if (this.disabled) return;
		this.removeValues([item.uuid]);
	}
}

export interface ChipValue {
	uuid: string;
	name: { language: string; value: string }[] | string;
	order?: number;
	addiotionalProperties?: Record<string, unknown>;
}
