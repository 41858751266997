import { Component, DestroyRef, HostBinding, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NavigationEnd, Router } from '@angular/router';
import { concatLatestFrom } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { distinct, filter, first, switchMap } from 'rxjs';
import { Permission } from 'src/app/shared/models';
import { OpenedMenuType } from 'src/app/shared/models/opened-menu-type';
import { ConfigurationService } from '../../../shared/services/configuration.service';
import { NavigationActions } from '../../state/app.actions';
import { ConfigSelector, NavigationSelector, UserSelector } from '../../state/app.selectors';

@Component({
	selector: 'datalean-main',
	templateUrl: './main.component.html',
	styleUrls: ['./main.component.scss'],
})
export class MainComponent implements OnInit {
	private store = inject(Store);
	private router = inject(Router);
	private configurationService = inject(ConfigurationService);
	protected destroyRef = inject(DestroyRef);

	@HostBinding('class.error') hasError = false;
	//false perchè subito non deve essere mostrato
	openMenuBoolean: boolean = false;
	editModeOn: boolean = false;
	showNavBar: boolean = false;
	showDamHeader: boolean = false;

	//Doc toast: https://www.npmjs.com/package/ngx-toastr
	constructor() {
		this.store
			.select(NavigationSelector.selectMenu)
			.pipe(takeUntilDestroyed())
			.subscribe((result) => {
				this.openMenu(result.menuLabel);
			});
		this.router.events.pipe(takeUntilDestroyed()).subscribe((event: unknown) => {
			if (event instanceof NavigationEnd) {
				const isProfileUrl = event.urlAfterRedirects?.includes('/profile');
				this.showNavBar = !(
					event.urlAfterRedirects?.includes('asset-edit') ||
					event.urlAfterRedirects?.includes('media-library/new') ||
					isProfileUrl
				);

				//mostro l'header solo quando sono in media-library
				this.showDamHeader = event.urlAfterRedirects === '/media-library';

				if (isProfileUrl) {
					this.openMenuBoolean = false;
				}

				//se la barra di navigazione deve essere visibile allora apro il menu (poichè probabilmente non ha nemmeno
				//la rotella per riaprirla, quindi di default deve essere aperto)
				if (this.showNavBar) this.openMenuBoolean = true;
			}
		});

		this.configurationService.init();
	}

	ngOnInit() {
		this.store
			.select(UserSelector.selectUserData)
			.pipe(
				filter((data) => !!data),
				switchMap(() =>
					this.store.select(ConfigSelector.selectConfig('hasDAM')).pipe(
						distinct(),
						concatLatestFrom(() => this.store.select(NavigationSelector.selectNavLinks).pipe(first())),
						takeUntilDestroyed(this.destroyRef)
					)
				)
			)
			.subscribe({
				next: ([hasDAM, navLinks]) => {
					// LO so che è brutto, aspetto una cosa più intelligente
					if (hasDAM?.value) {
						this.store.dispatch(
							NavigationActions.setNavLinks({
								navLinks: navLinks.map((link) => {
									if (link.label !== 'NAVIGATION.SETTINGS.SETTING_TITLE') {
										return link;
									}
									const hasMediaSettings = !!link.children?.find((l) => l.label === 'NAVIGATION.SETTINGS.MEDIA');
									return {
										...link,
										children: [
											...(!hasMediaSettings
												? [
														{
															label: 'NAVIGATION.SETTINGS.MEDIA',
															path: '/settings/media',
															requiredPermissions: [Permission.READ_SETTINGS],
														},
												  ]
												: []),
											...(link.children ?? []),
										],
									};
								}),
							})
						);
					} else {
						this.store.dispatch(
							NavigationActions.setNavLinks({
								navLinks: navLinks.map((link) => {
									if (link.label !== 'NAVIGATION.SETTINGS.SETTING_TITLE') {
										return link;
									}
									const children = link.children?.filter((child) => child.label !== 'NAVIGATION.SETTINGS.MEDIA');
									return {
										...link,
										children,
									};
								}),
							})
						);
					}
				},
			});
	}

	openMenu(type?: OpenedMenuType) {
		if (type === OpenedMenuType.MENU) {
			this.openMenuBoolean = !this.openMenuBoolean;
		} else if (type === OpenedMenuType.FILTER || type === OpenedMenuType.FEATURE) {
			//se il menù è aperto quando clicco i menù filter o feature, lo chiudo
			if (this.openMenuBoolean) {
				this.openMenuBoolean = false;
			}
		}
	}
}
