import { CdkAccordionModule } from '@angular/cdk/accordion';
import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { RouterModule } from '@angular/router';
import { Store, StoreModule } from '@ngrx/store';
import { distinctUntilChanged, filter, switchMap, tap } from 'rxjs';
import { NavLink } from '../../../shared/models';
import { SharedModule } from '../../../shared/shared.module';
import { NavigationActions } from '../../state/app.actions';
import { NavigationSelector, UserSelector } from '../../state/app.selectors';

@Component({
	selector: 'datalean-main-navbar',
	templateUrl: './main-navbar.component.html',
	styleUrls: ['./main-navbar.component.scss'],
	standalone: true,
	imports: [CommonModule, StoreModule, CdkAccordionModule, MatExpansionModule, SharedModule, RouterModule],
})
export class MainNavbarComponent {
	private store = inject(Store);

	links$ = this.store.select(UserSelector.selectUserData).pipe(
		filter((data) => !!data),
		switchMap((data) =>
			this.store.select(NavigationSelector.selectNavLinks).pipe(
				distinctUntilChanged((prev, curr) => {
					const areArraysEqual = prev.length === curr.length && prev.every((link, index) => link.label === curr[index].label && link.children?.length === curr[index].children?.length);
					return areArraysEqual && !!data;
				})
			)
		)
	);
	activePath$ = this.store.select(NavigationSelector.selectActivePath);

	constructor() {}

	selectedMenu(button: NavLink) {
		this.store.dispatch(NavigationActions.setActivePath({ activePath: button.path }));
	}
}
