import { createReducer, on } from '@ngrx/store';
import { NavigationActions } from 'src/app/core/state/app.actions';
import { MenuSelected } from '../../models/media-library/menu-selected-enum';
import { Permission } from '../../models/permission.enum';
import { NavigationState } from './navigation.state';

export const TAG = 'navigation';

const initialState: NavigationState = {
	navLinks: [
		{
			label: 'NAVIGATION.DASHBOARD',
			path: '/dashboard',
		},
		{
			label: 'NAVIGATION.PRODUCTS_VARIANTS',
			children: [
				{
					label: 'NAVIGATION.PRODUCTS_VARIANTS',
					path: '/products-variants',
					requiredPermissions: [Permission.READ_PRODUCTS_VARIANTS],
				},
				{
					label: 'NAVIGATION.FEATURES_VARIANTS',
					path: '/products-variants/features',
					requiredPermissions: [Permission.READ_FEATURES_VARIANTS],
				},
				{
					label: 'NAVIGATION.STRUCTURE_VARIANTS',
					path: '/products-variants/structures',
					requiredPermissions: [Permission.READ_STRUCTURE_VARIANTS],
				},
			],
		},
		{
			label: 'NAVIGATION.PRODUCTS',
			children: [
				{
					label: 'NAVIGATION.PRODUCTS',
					path: '/products',
					requiredPermissions: [Permission.READ_PRODUCTS],
				},

				// {
				// 	label: 'NAVIGATION.WISHLIST',
				// 	path: '/wishlist',
				// 	requiredPermissions: [Permission.READ_WISHLISTS],
				// },
				{
					label: 'NAVIGATION.FEATURES',
					path: '/products/features',
					requiredPermissions: [Permission.READ_FEATURES],
				},

				{
					label: 'NAVIGATION.STRUCTURE',
					path: '/products/structures',
					requiredPermissions: [Permission.READ_STRUCTURE],
				},
				{
					label: 'NAVIGATION.WORKFLOW',
					path: '/products/workflow',
					requiredPermissions: [Permission.READ_WORKFLOW],
				},

				// {
				// 	label: 'NAVIGATION.USERS_STRUCTURES',
				// 	path: '/users-structures',
				// 	requiredPermissions: [Permission.READ_STRUCTURE],
				// },
			],
		},
		{
			label: 'NAVIGATION.CONTENTS',
			requiredPermissions: [Permission.READ_CONTENTS],
			children: [
				{
					label: 'NAVIGATION.CONTENTS',
					path: '/contents',
					requiredPermissions: [Permission.READ_CONTENTS],
				},
				{
					label: 'NAVIGATION.FEATURES',
					path: '/contents/features',
					requiredPermissions: [Permission.READ_FEATURES],
				},
				{
					label: 'NAVIGATION.STRUCTURE',
					path: '/contents/structures',
					requiredPermissions: [Permission.READ_STRUCTURE],
				},
			],
		},
		{
			label: 'NAVIGATION.COMMUNICATIONS',
			requiredPermissions: [Permission.READ_COMMUNICATIONS],
			children: [
				{
					label: 'NAVIGATION.COMMUNICATIONS',
					path: '/communications',
					requiredPermissions: [Permission.READ_COMMUNICATIONS],
				},
				{
					label: 'NAVIGATION.FEATURES',
					path: '/communications/features',
					requiredPermissions: [Permission.READ_FEATURES],
				},
				{
					label: 'NAVIGATION.STRUCTURE',
					path: '/communications/structures',
					requiredPermissions: [Permission.READ_STRUCTURE],
				},
			],
		},
		{
			label: 'NAVIGATION.MEDIA_LIBRARY._GROUP',
			requiredPermissions: [Permission.READ_MEDIA_LIBRARY],
			children: [
				{
					path: '/media-library',
					label: 'NAVIGATION.MEDIA_LIBRARY.LIBRARY',
					requiredPermissions: [Permission.READ_MEDIA_LIBRARY],
				},
				{
					label: 'NAVIGATION.FEATURES',
					path: '/media-library/features',
					requiredPermissions: [Permission.READ_FEATURES],
				},
				{
					label: 'NAVIGATION.MEDIA_LIBRARY.TAG',
					path: '/media-library/tags',
				},
			],
		},
		{
			label: 'NAVIGATION.SURVEY',
			requiredPermissions: [Permission.READ_SURVEY],
			path: '/surveys',
		},
		{
			label: 'NAVIGATION.USEFUL_LINKS.USEFUL_LINKS',
			requiredPermissions: [Permission.READ_COCKPIT_COMMUNICATIONS],
			children: [
				{
					label: 'NAVIGATION.USEFUL_LINKS.USEFUL_LINKS',
					path: '/useful-links',
				},
				{
					label: 'NAVIGATION.USEFUL_LINKS.STRUCTURES',
					path: '/useful-links/structures',
					requiredPermissions: [Permission.READ_STRUCTURE],
				},
			],
		},
		{
			label: 'NAVIGATION.IDM.IDENTITY_MANAGER',
			requiredPermissions: [Permission.READ_IDM],
			children: [
				{
					label: 'NAVIGATION.IDM.DATALEAN_USERS',
					path: '/idm/datalean-users',
					requiredPermissions: [Permission.READ_DATALEAN_USERS],
				},
				{
					label: 'NAVIGATION.IDM.APPLICATION_USERS',
					path: '/idm/app-users',
					requiredPermissions: [Permission.READ_APPLICATION_USERS],
				},
				{
					label: 'NAVIGATION.IDM.APPLICATION_USER_STRUCTURES',
					path: '/idm/app-users/structures',
					requiredPermissions: [Permission.READ_STRUCTURE],
				},
				{
					label: 'NAVIGATION.IDM.GROUPS',
					path: '/idm/groups',
					requiredPermissions: [Permission.READ_APPLICATION_GROUPS],
				},
				{
					label: 'NAVIGATION.IDM.SMART_GROUPS',
					path: '/idm/smart-groups',
					requiredPermissions: [Permission.READ_APPLICATION_GROUPS],
				},
				{
					label: 'NAVIGATION.ROLES',
					path: '/idm/roles',
					requiredPermissions: [Permission.READ_ROLES],
				},
			],
		},
		{
			label: 'NAVIGATION.RESERVATIONS.RESERVATIONS',
			requiredPermissions: [Permission.READ_RESERVATIONS],
			children: [
				{
					label: 'NAVIGATION.RESERVATIONS.CALENDAR',
					path: '/reservations/calendar',
					requiredPermissions: [Permission.READ_RESERVATIONS],
				},
				{
					label: 'NAVIGATION.RESERVATIONS.RULES',
					path: '/reservations/rules',
					requiredPermissions: [Permission.READ_RESERVATIONS],
				},
				{
					label: 'NAVIGATION.RESERVATIONS.RESOURCES',
					path: '/reservations/resources',
					requiredPermissions: [Permission.READ_RESERVATIONS],
				},
				{
					label: 'NAVIGATION.RESERVATIONS.STRUCTURE.RESOURCES',
					path: '/reservations/resources/structures',
					requiredPermissions: [Permission.READ_STRUCTURE],
				},
				{
					label: 'NAVIGATION.RESERVATIONS.PRODUCTS',
					path: '/reservations/products',
					requiredPermissions: [Permission.READ_RESERVATIONS],
				},
				{
					label: 'NAVIGATION.RESERVATIONS.STRUCTURE.PRODUCTS',
					path: '/reservations/products/structures',
					requiredPermissions: [Permission.READ_STRUCTURE],
				},
				{
					label: 'NAVIGATION.RESERVATIONS.PRODUCT_FEATURES',
					path: '/reservations/features',
					requiredPermissions: [Permission.READ_RESERVATIONS],
				},
			],
		},
		{
			label: 'NAVIGATION.LMS.LMS',
			requiredPermissions: [Permission.READ_LEARNING_MANAGEMENT_SYSTEM],
			children: [
				{
					label: 'NAVIGATION.LMS.COURSES',
					path: '/lms/courses',
				},
				{
					label: 'NAVIGATION.LMS.LESSONS',
					path: '/lms/lessons',
				},
				{
					label: 'NAVIGATION.LMS.QUIZ',
					path: '/lms/quiz',
				},
				{
					label: 'NAVIGATION.LMS.CATEGORY_LESSONS',
					path: '/lms/features',
				},
			],
		},
		{
			label: 'NAVIGATION.CONTESTS.CONTESTS',
			requiredPermissions: [Permission.READ_CONTESTS],
			children: [
				{
					label: 'NAVIGATION.CONTESTS.CONTESTS',
					path: '/contests',
					requiredPermissions: [Permission.READ_CONTESTS],
				},
				{
					label: 'NAVIGATION.CONTESTS.CHALLENGES',
					path: '/contests/challenges',
					requiredPermissions: [Permission.READ_CONTESTS],
				},
			],
		},
		{
			label: 'NAVIGATION.SETTINGS.SETTING_TITLE',
			children: [
				{
					label: 'NAVIGATION.SETTINGS.MEDIA',
					path: '/settings/media',
					requiredPermissions: [Permission.READ_SETTINGS],
				},
				{
					label: 'NAVIGATION.SETTINGS.CONFIGURATIONS',
					path: '/settings/configurations',
					requiredPermissions: [Permission.READ_CONFIGURATION],
				},
				{
					label: 'NAVIGATION.SETTINGS.COMMUNITIES',
					path: '/settings/communities',
					requiredPermissions: [Permission.VIEW_COMMUNITIES],
				},
				// {
				// 	label: 'NAVIGATION.SETTINGS.BRAND',
				//per ora commentato perchè non c'è da visualizzare il contenuto ma solo il nome del componente
				//path: '/settings/brand',
				// requiredPermissions: [Permission.READ_SETTINGS],
				// },
				{
					label: 'NAVIGATION.SETTINGS.INTEGRATIONS',
					path: '/settings/integrations',
					requiredPermissions: [Permission.READ_INTEGRATIONS],
				},
				// {
				// 	label: 'NAVIGATION.SETTINGS.USERS_AND_GROUPS',
				// 	path: '/settings/users-and-groups',
				// 	requiredPermissions: [
				// 		Permission.READ_APPLICATION_GROUPS,
				// 		Permission.READ_APPLICATION_USERS,
				// 		Permission.READ_DATALEAN_USERS,
				// 		Permission.READ_STRUCTURE,
				// 	],
				// },
			],
		},
		{
			label: 'NAVIGATION.PICTOGRAMS.PICTOGRAMS',
			requiredPermissions: [Permission.READ_PICTOGRAMS],
			children: [
				{ label: 'NAVIGATION.PICTOGRAMS.PICTOGRAMS', path: '/pictograms', requiredPermissions: [Permission.READ_PICTOGRAMS] },
				{ label: 'NAVIGATION.PICTOGRAMS.STRUCTURES', path: '/pictograms/structures', requiredPermissions: [Permission.READ_PICTOGRAMS] },
			],
		},
	],
	menuLabel: '',
	hasTreeBrowser: false,
	activePath: '',
};

export const counterReducer = createReducer(
	initialState,
	on(NavigationActions.setNavLinks, (state: NavigationState, { navLinks }) => ({
		...state,
		navLinks,
	})),
	on(NavigationActions.selectedMenu, (state: NavigationState, { menuLabel }) => ({
		...state,
		menuLabel: menuLabel,
	})),
	on(NavigationActions.setActivePath, (state: NavigationState, { activePath }) => ({
		...state,
		activePath: activePath,
		hasTreeBrowser: !!activePath && Object.values<string>(MenuSelected).includes(activePath),
	}))
);
