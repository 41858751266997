import { CdkDrag, CdkDragDrop, CdkDropList } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, forwardRef, inject } from '@angular/core';
import {
	AbstractControl,
	ControlContainer,
	ControlValueAccessor,
	FormControl,
	FormGroupDirective,
	NG_VALIDATORS,
	NG_VALUE_ACCESSOR,
	ReactiveFormsModule,
	UntypedFormArray,
	UntypedFormControl,
	UntypedFormGroup,
	ValidationErrors,
	Validator,
	ValidatorFn,
	Validators,
} from '@angular/forms';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';
import {
	CheckboxComponent,
	CheckboxPrivacyComponent,
	DatePickerComponent,
	EmailComponent,
	ExtraStructureFieldType,
	FeatureValueType,
	HtmlEditorComponent,
	InputComponent,
	PhoneComponent,
	RadioFieldComponent,
	SelectFieldComponent,
	SharedModule,
	StructureField,
	StructureFieldType,
	TextareaComponent,
	ToLocalizedValuePipe,
} from 'addiction-components';
import { Subject, takeUntil } from 'rxjs';
import { environment } from 'src/environments/environment';
import { v4 } from 'uuid';
import { ContentType, EntityType } from '../../models';
import { SurveyQuestionType } from '../../models/survey';
import { RolesService } from '../../services/roles.service';
import { UserDataService } from '../../services/user/user-data.service';
import { GenericChipSelectorComponent } from '../chips-selector/datalean-generic-chip-selector';
import { FeaturesChipsSelectorComponent } from '../chips-selector/features-chips-selector.component';
import { LessonsChipsSelectorComponent } from '../chips-selector/lessons-chips-selector.component';
import { QuizChipsSelectorComponent } from '../chips-selector/quiz-chips-selector.component';
import { RoleChipsSelectorComponent } from '../chips-selector/role-chips-selector.component';
import { HotspotImageContainerComponent } from '../hotspot-image-container/hotspot-image-container.component';
import { HotspotComponent } from '../hotspot/hotspot.component';
import { PictogramSelectorComponent } from '../pictogram-selector/pictogram-selector.component';
import { AvailabilityOptionComponent } from '../reservations/availability-option/availability-option.component';
import { AvailabilityRuleListComponent } from '../reservations/availability-rule-list/availability-rule-list.component';
import { StructureFileSelectorComponent } from '../structure-file-selector/structure-file-selector.component';

@Component({
	selector: 'datalean-container',
	templateUrl: './container.component.html',
	styleUrls: ['./container.component.scss'],
	standalone: true,
	imports: [
		CommonModule,
		ReactiveFormsModule,
		TranslateModule,
		MatSlideToggleModule,
		CdkDropList,
		CdkDrag,
		SharedModule,
		FeaturesChipsSelectorComponent,
		HtmlEditorComponent,
		TextareaComponent,
		CheckboxComponent,
		StructureFileSelectorComponent,
		LessonsChipsSelectorComponent,
		RadioFieldComponent,
		QuizChipsSelectorComponent,
		PhoneComponent,
		EmailComponent,
		SelectFieldComponent,
		InputComponent,
		DatePickerComponent,
		GenericChipSelectorComponent,
		HotspotComponent,
		CdkDrag,
		HotspotImageContainerComponent,
		CheckboxPrivacyComponent,
		AvailabilityOptionComponent,
		AvailabilityRuleListComponent,
		RoleChipsSelectorComponent,
		PictogramSelectorComponent,
	],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			multi: true,
			useExisting: forwardRef(() => ContainerComponent),
		},
		{
			provide: NG_VALIDATORS,
			multi: true,
			useExisting: forwardRef(() => ContainerComponent),
		},
		RolesService,
	],
})
export class ContainerComponent<T = unknown> implements OnInit, OnDestroy, ControlValueAccessor, Validator {
	readonly FeatureValueType = FeatureValueType;
	readonly SurveyQuestionType = SurveyQuestionType;
	readonly types = ExtraStructureFieldType;

	private structureMap: Record<string, { initialStructure: StructureField; releatedStructure?: StructureField }> = {};
	private userService = inject(UserDataService);
	private group = inject(ControlContainer, { skipSelf: true });
	private localizePipe = inject(ToLocalizedValuePipe);
	private translate = inject(TranslatePipe);
	@Input() defaultValues?: T;
	@Input({
		transform: (sfs: StructureField[]) => {
			return sfs.map((sf) => {
				// console.log('sf', sf);
				//non mi interessa andare in profondità perchè se fosse un container verrebbe istanziato un altro ContainerComponent al livello inferiore
				if (sf.extra && typeof sf.extra === 'string') {
					try {
						sf.parsedExtra = JSON.parse(sf.extra);
					} catch (e) {
						console.error('Error parsing extra', sf.extra);
					}
				}

				return sf;
			});
		},
	})
	structureFields: StructureField[] | undefined;
	@Input() depth = 0;
	@Input() readonly = false;
	@Output() indexElementDeleted = new EventEmitter<number>();

	isCollapsed: Record<string, boolean> = {};
	isFieldCollapsed: boolean[] = [];
	formGroup = new UntypedFormGroup({});
	onDestroy$ = new Subject<boolean>();

	constructor() {}

	ngOnInit(): void {
		this.structureFields = structuredClone(this.structureFields);

		// console.log('ContainerComponent constructor', this.structureFields);
		if (this.group instanceof FormGroupDirective) {
			// console.log('ContainerComponent ngOnInit FORM', this.group.form);
			this.formGroup = this.group.form;
		} else {
			// console.log('ContainerComponent ngOnInit', this.group.valid);
		}

		if (this.structureFields) {
			for (const sf of this.structureFields) {
				// se è presente il parametro structureConnected significa che c'è una struttura correlata
				if (sf.structureConnected) {
					// trovo la structureField a cui è collegata quella che sto ciclando,
					// creo una mappa che le contenga entrambe
					this.structureMap[sf.name] = {
						initialStructure: sf,
						releatedStructure: this.structureFields.find((field) => field.uuid === sf.structureConnected),
					};
				}

				if (sf.type === ExtraStructureFieldType.SINGLE_RELATED_ENTITY) {
					// TODO Rimuovere questo a regime, dovrebbe essere una sottostruttura o extra
					// e questo componente non dovrebbe occuparsene ma dovrebbe esserci la specializzazione per i corsi
					this.formGroup.addControl('uuid', new UntypedFormControl(v4(), Validators.required));
					this.formGroup.addControl('name', new UntypedFormControl(null, Validators.required));
					this.formGroup.addControl('active', new UntypedFormControl(true, Validators.required));
					this.formGroup.addControl('linkedLesson', new UntypedFormControl(null, Validators.required));
					this.formGroup.addControl('blockedBy', new UntypedFormControl());
				}

				// console.log('ContainerComponent ngOnInit', sf.name);
				// console.log('ContainerComponent ngOnInit', sf);
				// console.log('ContainerComponent ngOnInit', this.formGroup);
				// console.log('ContainerComponent ngOnInit', this.formGroup.controls[sf.name]);
				if (this.formGroup.get(sf.name) === null) {
					const validators: ValidatorFn[] = [];

					//caso possibile solo per hotspot
					if (sf.name === HotspotImageContainerComponent.hotspotPositionX || sf.name === HotspotImageContainerComponent.hotspotPositionY) {
						validators.push(Validators.max(100), Validators.min(0));
					}
					//fine caso possibile solo per hotspot

					if (sf.isRequired) {
						validators.push(Validators.required);
					}
					if (sf.repeatable) {
						if (!sf.parsedExtra || (typeof sf.parsedExtra === 'object' && 'parentControl' in sf.parsedExtra && !sf.parsedExtra['parentControl'])) {
							let def: FormControl[] = [];
							if (this.defaultValues && typeof this.defaultValues === 'object' && sf.name in this.defaultValues) {
								const arr = this.defaultValues[sf.name as keyof typeof this.defaultValues];
								if (Array.isArray(arr)) {
									def = arr?.map((data) => new FormControl(data)) ?? [];
								}
							}
							this.formGroup.addControl(sf.name, new UntypedFormArray(def, validators));
						}
					} else if (sf.type === StructureFieldType.CONTAINER || sf.type === ExtraStructureFieldType.SURVEY_DOMAIN_OF_ANSWERS) {
						this.formGroup.addControl(sf.name, new UntypedFormGroup({}, validators));
					} else {
						let predefinedValue = this.localizePipe.transform(sf.predefinedValue) || null;

						if (predefinedValue) {
							if (sf.type === StructureFieldType.TOGGLE || sf.type === StructureFieldType.BOOLEAN) {
								if (typeof predefinedValue === 'string') predefinedValue = predefinedValue === 'true';
								else predefinedValue = !!predefinedValue;
							}
						}
						this.formGroup.addControl(sf.name, new FormControl(predefinedValue, validators));
					}
				}

				if (sf.rolesCantSee?.length || sf.rolesReadonly?.length) {
					// console.log('sf', sf);
					const hidden = this.userService.authenticatedUser?.roles.some((role) => {
						// console.log('role', role);
						return sf.rolesCantSee?.some((r) => r.uuid === role.uuid);
					});
					const readonly = this.userService.authenticatedUser?.roles.some((role) => {
						return sf.rolesReadonly?.some((r) => r.uuid === role.uuid);
					});

					// console.log('hidden', hidden);
					// console.log('readonly', readonly);

					if (hidden || sf.hidden) {
						this.formGroup.removeControl(sf.name);
					} else if (readonly) {
						sf.readOnly = true;
					}
				}

				if (sf.readOnly || this.readonly) {
					const control = this.formGroup.get(sf.name);
					if (control) {
						control.disable();
					}
				}
			}
		}
		// console.log('ContainerComponent ngOnInit', this.formGroup.valid);

		if (this.defaultValues) {
			this.formGroup.patchValue(this.defaultValues);
		}

		// rimango in ascolto sulle modifiche sui singoli controlli di tipo select che hanno anche un relatedStructure
		// se this.structureMap cambia questa gestione non è più valida perchè non verrebbe aggiornata
		for (const initialField of Object.keys(this.structureMap)) {
			const structure = this.structureMap[initialField];
			if (structure.releatedStructure && structure.initialStructure.type === StructureFieldType.SELECT) {
				this.updateFormatAndInputRequired(this.formGroup.controls[initialField].value, structure.releatedStructure);
				this.formGroup.controls[initialField]?.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe((sf) => {
					if (structure.releatedStructure) {
						this.updateFormatAndInputRequired(sf, structure.releatedStructure);
					}
				});
			}
		}
		//TODO: non ascoltare il change del form ma dei controlli corretti
		// this.formGroup.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe((newStructure) => {
		// 	if (newStructure) {
		// 		for (const initialField of Object.keys(this.structureMap)) {
		// 			const structure = this.structureMap[initialField];
		// 			if (newStructure[initialField] && structure.releatedStructure && structure.initialStructure.type === StructureFieldType.SELECT) {
		// 				this.updateFormatAndInputRequired(newStructure[initialField], structure.releatedStructure);
		// 			}
		// 		}
		// 	}
		// 	// console.log('ContainerComponent ngOnInit - ', this.depth, this.formGroup.errors);
		// });

		// console.log('ContainerComponent ngOnInit - ', this.depth, this.formGroup);
		this.formGroup.updateValueAndValidity({ onlySelf: true });

		this.structureFields = [...(this.structureFields ?? [])];
	}

	getInfo(field: StructureField) {
		let data:
			| {
					endpoint: string;
					entityType: string;
					modalTitle: string;
					filters: string[];
					additionalParams?: { structureUUID: string };
			  }
			| undefined;

		if (field.type == StructureFieldType.RELATED_ENTITIES && field.relatedStructureType) {
			const [type, structure] = field.relatedStructureType.split('|');
			const filters = ['uuid', 'name'];
      const modalTitle = this.localizePipe.transform(field?.label)
			if (type.toLowerCase() === EntityType.PRODUCT) {
				data = {
					endpoint: environment.productUrl,
					entityType: EntityType.PRODUCT,
					modalTitle: modalTitle ?? this.translate.transform('PRODUCTS.PRODUCT_EDITOR.MODALTITLE.RELATED_ENTITIES'),
					filters: filters,
				};

				if (structure) {
					data.additionalParams = { structureUUID: structure };
				}
			}
			if (type.toLowerCase() === EntityType.PRODUCTVARIANTS) {
				data = {
					endpoint: environment.productVariantsUrl ?? '',
					entityType: EntityType.PRODUCTVARIANTS,
					modalTitle: modalTitle ?? this.translate.transform('PRODUCTS.PRODUCT_EDITOR.MODALTITLE.RELATED_ENTITIES'),
					filters: filters,
				};

				if (structure) {
					data.additionalParams = { structureUUID: structure };
				}
			}
			if (type.toLowerCase() === EntityType.COMMUNICATION) {
				data = {
					endpoint: environment.communicationUrl,
					entityType: EntityType.COMMUNICATION,
					modalTitle: modalTitle ?? this.translate.transform('COMMUNICATIONS.COMMUNICATION_EDITOR.MODALTITLE.RELATED_ENTITIES'),
					filters: filters,
				};
				if (structure) {
					data.additionalParams = { structureUUID: structure };
				}
			}

			if (type.toLowerCase() === EntityType.CONTENT) {
				data = {
					endpoint: environment.contentUrl,
					entityType: EntityType.CONTENT,
					modalTitle: modalTitle ?? this.translate.transform('CONTENTS.CONTENTS_EDITOR.MODALTITLE.RELATED_ENTITIES'),
					filters: filters,
				};
				if (structure) {
					data.additionalParams = { structureUUID: structure };
				}
			}
		}
		if (field.type == ExtraStructureFieldType.PICTOGRAM) {
			const filters = ['uuid', 'name1'];
			if (environment.pictogramUrl) {
				data = {
					endpoint: environment.pictogramUrl + 'pictogram',
					entityType: EntityType.PRODUCT,
					modalTitle: this.translate.transform('PICTOGRAMS.PICTOGRAMS'),
					filters: filters,
				};
			}
		}
		return data;
	}

	toggleCollapseState(state: boolean): boolean {
		return !state;
	}

	toggleCollapse(field: StructureField) {
		this.isCollapsed[field.name] = !this.isCollapsed[field.name];
	}

	toggleCollapseField(index: number): void {
		this.isFieldCollapsed[index] = !this.isFieldCollapsed[index];
	}

	private updateFormatAndInputRequired(selectedType: string, structureRelated: StructureField): void {
		if (selectedType && structureRelated) {
			const type = selectedType.toLowerCase();
			let format = '';
			let inputRequired = '';

			if (type === ContentType.VIDEO.toLowerCase()) {
				format = 'mp4,mov,wmv,avi,flav';
				inputRequired = 'video';
			} else if (type === ContentType.DOCUMENT.toLowerCase()) {
				format = '';
				inputRequired = '';
			}
			structureRelated.inputTypeRequired = inputRequired;
			structureRelated.formatRequired = format;
			structureRelated.parentValue = selectedType;
		}
		// if (structureRelated) {
		// 	this.structureFields = this.structureFields?.map((x) => {
		// 		if (structureRelated.uuid === x.uuid) {
		// 			return structuredClone(structureRelated);
		// 		}
		// 		return x;
		// 	});
		//   // this.structureFields = structuredClone(this.structureFields)
		// }
	}

	public onTouched: () => void = () => {};

	onValidatorChange = () => {
		// console.log('onValidatorChange', this.formGroup);
	};

	registerOnValidatorChange(fn: () => void): void {
		// console.log('registerOnValidatorChange', fn);
		this.formGroup.updateValueAndValidity({ onlySelf: true, emitEvent: false });

		// console.log('registerOnValidatorChange', this.group);
		if (this.group.control) this.validate(this.group.control);
	}

	validate(c: AbstractControl): ValidationErrors | null {
		/**
		 * Perchè serve questo if?
		 * Praticamente quando andiamo a lavorare con dei ripetibili
		 * può capitare che il container sia valido ma i suoi figli no
		 * questo perchè il container ripetibile è un formArray di formControl
		 * e quindi non è possibile andare a validare il formArray
		 */
		// console.log('onValidatorChange', this.formGroup);

		if (c.valid && this.formGroup.invalid) {
			c.setErrors({ ...this.formGroup.errors, [this.depth]: true });
			c.markAsDirty();
		} else if (c.invalid && this.formGroup.valid) {
			const errors = c.errors;
			if (errors) delete errors[this.depth];
			c.setErrors(errors);
			c.markAsPristine();
		} else if (c.invalid && this.formGroup.invalid && !c.errors) {
			c.setErrors({ ...this.formGroup.errors, [this.depth]: true });
			c.markAsDirty();
		}
		return c.invalid || this.formGroup.invalid ? { ...c.errors, ...this.formGroup.errors } : null;
	}

	registerOnTouched(fn: () => void): void {
		this.onTouched = fn;
	}

	writeValue(obj: Record<string, unknown>): void {
		this.formGroup.patchValue(obj);
		// if (obj) this.formGroup.setValue(obj);
	}

	registerOnChange(fn: (val: T) => void): void {
		// console.log('registerOnChange', this.group);
		if (!(this.group instanceof FormGroupDirective)) {
			// console.log('registerOnChange yes');

			this.formGroup.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe(fn);
		}
	}

	setDisabledState(disabled: boolean): void {
		// console.log('setDisabledState', disabled);
		if (disabled) this.formGroup.disable({ emitEvent: false });
		else this.formGroup.enable({ emitEvent: false });
	}

	drop(event: CdkDragDrop<UntypedFormArray | undefined>, control: UntypedFormArray | undefined) {
		if (control) {
			const el = control.at(event.previousIndex);
			control.removeAt(event.previousIndex);
			control.insert(event.currentIndex, el);
		}
	}

	addChild(field: StructureField): void {
		const control = this.formGroup.controls[field.name] as UntypedFormArray;
		// console.log(control, 'control');
		control.push(new FormControl());
		// this.formGroup.patchValue(this.control?.value);
		this.formGroup.updateValueAndValidity();
	}

	removeChild(field: StructureField, index: number): void {
		const control = this.formGroup?.controls[field.name] as UntypedFormArray;
		this.indexElementDeleted.emit(index);
		control.removeAt(index);
		this.formGroup.updateValueAndValidity();
	}

	ngOnDestroy(): void {
		this.onDestroy$.next(true);
		this.onDestroy$.complete();
	}

	getUntypedFormControl(control?: AbstractControl | null) {
		return control as UntypedFormControl;
	}

	getUntypedFormGroup(control?: AbstractControl | null) {
		return control as UntypedFormGroup;
	}

	getFormArray(field: StructureField): UntypedFormArray | undefined {
		// console.log('field', this.formGroup.controls[field.name]);
		return this.formGroup.get(field.name) as UntypedFormArray;
	}

	getFormArrayControls(field: StructureField): Array<FormControl> | undefined {
		return this.getFormArray(field)?.controls as Array<FormControl>;
	}

	getFormGroup(field: StructureField): UntypedFormGroup {
		return this.formGroup.get(field.name) as UntypedFormGroup;
	}

	getFormControl(field: StructureField): UntypedFormControl {
		return this.formGroup.get(field.name) as UntypedFormControl;
	}

	getClass(formKey: string) {
		if (this.structureFields) {
			const ratio = this.structureFields.find((sf) => sf.name === formKey)?.desktopRatio;
			if (ratio !== undefined) {
				return `ratio-${ratio}`;
			}
		}
		return 'ratio-100';
	}

	getExtra<T = undefined>(field: StructureField, name: string): T | undefined {
		if (!field.parsedExtra) return undefined;
		if (Array.isArray(field.parsedExtra)) return field.parsedExtra.find((extra) => extra.label === name)?.value as T | undefined;
		if (Object.keys(field.parsedExtra).includes('label'))
			return (field.parsedExtra as { url: string; label: { [key: string]: string } })?.label[name] as T | undefined;
		if(Object.keys(field.parsedExtra).includes(name)) {
			return (field.parsedExtra as Record<string, unknown>)[name] as T ;
		}
		return undefined;
	}

	getSubDataset<U>(name: string, index?: number): U | undefined {
		const dataset = this.defaultValues?.[name as keyof T];
		if (dataset && index !== undefined) {
			return (dataset as U[])[index];
		}
		return dataset as U;
	}

	isDisabledAddChild(field: StructureField): boolean {
		const control = this.formGroup.controls[field.name];
		if (!control || control.disabled) return true;
		if (control instanceof UntypedFormArray) {
			const lastControl = control.at(control.length - 1);
			return lastControl?.value?.type === ContentType.QUIZ;
		}
		return true;
	}

	getSurveyAnswersFor(field: StructureField) {
		if (field.fields) {
			switch (field.parentValue) {
				case SurveyQuestionType.CHOICE:
					return field.fields.filter((f) => ['answers', 'multiple'].includes(f.name));
				case SurveyQuestionType.RATING_SCALE:
					return field.fields.filter((f) => ['min', 'max'].includes(f.name));
				case SurveyQuestionType.DATE_TIME:
					return field.fields.filter((f) => ['showDate', 'showTime'].includes(f.name));
				case SurveyQuestionType.TEXT:
					return field.fields.filter((f) => ['textType'].includes(f.name));
			}
		}
		return [];
	}

	trackByFn(index: number) {
		return index;
	}

	getField(name: string): StructureField | undefined {
		return this.structureFields?.find((sf) => sf.name === name);
	}
}
