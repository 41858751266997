import { createAction, props } from '@ngrx/store';
import { Sort } from '@angular/material/sort';
import { PageEvent } from '@angular/material/paginator';
import { FeatureValue, Page, SearchInfo, Structure } from 'addiction-components';
import { HttpErrorResponse } from '@angular/common/http';
import { Role, STATE_STATUS } from 'src/app/shared/models';
import { RoleError } from './roles.state';

export const setData = createAction('[Roles] Set Roles Data', props<{ data: object }>());
export const setSearchInfo = createAction('[Roles] Set search info', props<{ searchInfo: SearchInfo }>());
export const setSort = createAction('[Roles] Set sort', props<{ sort: Sort }>());
export const setPages = createAction('[Datalean Lessons] Set pages', props<{ pages: number[] }>());
export const setRoleStructureUuid =  createAction('[Roles] Set Structure Role UUID', props<{ structureUUID?: string  }>());

export const setRolesSuccess = createAction(
	'[Roles] Set Roles Success',
	props<{ data: { totalRolesCount: number; pages: number[]; roles: Role[][] } }>()
);
export const setRolesError = createAction('[Roles] Set Roles Error', props<{ error: HttpErrorResponse }>());

export const setStructures = createAction('[Roles] Set Roles Structures', props<{ structures: Array<Structure> }>());
export const setFeatures = createAction('[Roles] Set Roles Features', props<{ features: Array<FeatureValue> }>());
export const addFeatureValueToFilters = createAction('[Roles] Add FeatureValue To Filters', props<{ featureValueUUID: string }>());
export const removeFeatureValueFromFilters = createAction('[Roles] Remove FeatureValue From Filters', props<{ featureValueUUID: string }>());
export const updatePage = createAction('[Roles] Update Page', props<{ updatedPage: Partial<Page> }>());
export const deleteRoles = createAction('[Roles] Delete Roles', props<{ uuids: string[] }>());
export const disableRoles = createAction('[Roles] Disable Roles', props<{ uuids: string[] }>());

export const setForceRefreshRoles = createAction('[Roles] Set Force Refresh');
export const setRoles = createAction('[Roles] Set Roles');
export const setDeleteRoles = createAction('[Roles] Set Delete Roles', props<{ role: Role }>());
export const setDeleteRolesSuccess = createAction('[Roles] Set Application Roles Success');
export const setDeleteRolesError = createAction('[Roles] Set Delete Roles Error', props<{ error: HttpErrorResponse }>());
export const setDisableRolesError = createAction('[Roles] Set Delete Contents Error', props<{ error: HttpErrorResponse }>());
export const setDisableRolesSuccess = createAction('[Roles] Set Disable Contents Success');
export const setStatus = createAction('[Roles] Set status', props<{ status: STATE_STATUS }>());
export const setGridSearch = createAction('[Roles] Set search', props<{ search: string | null }>());
export const setCachedPages = createAction('[Roles] Set cached pages', props<{ pages: number[] }>());
export const resetCache = createAction('[Roles] reset cache');
export const setProcuctStructureUuid =  createAction('[Roles] Set Structure Roles UUID', props<{ structureUUID?: string  }>());
export const error = createAction('[Roles] Error', props<{ error: RoleError }>());

