import { Component, Input, OnInit, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { DataleanTableConfiguration, PaginationInfo, Parts, SearchComponent, TableRow } from 'addiction-components';
import { Subject, combineLatest, debounceTime, map, shareReplay, switchMap, tap } from 'rxjs';
import { ApplicationSelector } from 'src/app/core/state/app.selectors';
import { LessonsService } from 'src/app/pages/lms/lessons/services/lessons.service';
import { Lesson } from '../../models';
import { EntitySelectorDialogComponent } from '../entity-selector-dialog/entity-selector-dialog.component';
import { CommonModule } from '@angular/common';
import { LetDirective } from '@ngrx/component';
import { DataleanTableComponent } from '../datalean-table/datalean-table.component';
import { TranslateModule } from '@ngx-translate/core';
import { LottieModule } from 'ngx-lottie';

@Component({
	selector: 'datalean-lessons-selector-dialog',
	templateUrl: './lessons-selector-dialog.component.html',
	styleUrls: ['./lessons-selector-dialog.component.scss'],
	standalone: true,
	imports: [CommonModule, TranslateModule, LetDirective, SearchComponent, DataleanTableComponent, EntitySelectorDialogComponent, LottieModule],
})
export class LessonsSelectorDialogComponent extends EntitySelectorDialogComponent implements OnInit {
	private lessonsSrv = inject(LessonsService);
	private store = inject(Store);

	@Input() limit?: number;

	structureUUID$ = new Subject<string>(); // <-- this filter is mandatory

	fetchParamsLesson$ = combineLatest([this.currentPage$, this.search$, this.sort$, this.store.select(ApplicationSelector.selectActiveLanguage)]).pipe(
		debounceTime(150)
	);

	override tableRows$ = this.fetchParamsLesson$.pipe(
		tap(() => (this.loading = true)),
		// recupera i dati dal server
		switchMap(([pagination, search, sort, locale]) =>
			this.lessonsSrv.getLessons({ parts: [Parts.EMPTY], locale, pagination, sort, filters: { query: search } })
		),
		// notifica cambiamento pagina alla tabella
		tap(({ paginationInfo }) => this.changePage(paginationInfo)),
		map(({ result }) => this.mapData(result)),
		tap((rows) => {
			this.loading = false;
			this._currentPageUUIDs = rows.map((r) => r['uuid'] as string);
		}),
		shareReplay({ refCount: true, bufferSize: 1 })
	);

	override selectedRows$ = combineLatest([this.tableRows$, this.updateSelectedObs$]).pipe(
		map(([rows]) => rows),
		map((rows) => rows.filter((row) => this.selectedUUIDs?.includes(row['uuid'] as string)))
	);

	override baseTableConfig: DataleanTableConfiguration<TableRow> = {
		columns: [
			{
				columnDef: 'uuid',
				header: 'STRUCTURE_FIELDS.UUID',
        thClass: 'single',
        tdClass: 'single',
        template: 0
			},
			{
				columnDef: 'name',
				header: 'STRUCTURE_FIELDS.NAME',
        thClass: 'double',
        tdClass: 'double',
        template: 1
			},
		],
	};

	constructor() {
		super();
	}

	ngOnInit(): void {
		// quando cambiano alcuni filtri, deve tornare ad inizio pagina
		combineLatest([this.sort$]).subscribe(() => this.goToPage(0));

		this.loading = true;
	}

	goToPage(pageNumber: number) {
		this.currentPage$.next(new PaginationInfo(this.pageSize, pageNumber));
	}

	filterByName(name: string | null) {
		this.search$.next(name ?? undefined);
	}

	override mapData(lessons: Lesson[]): TableRow[] {
		// console.log('mapData', lessons);
		return lessons.map((prod) => ({
			uuid: prod.uuid,
			name: prod.name,
		}));
	}

	override close() {
		// console.log('close', this.selectedUUIDs);
		this.closeDialog({ uuids: this.selectedUUIDs ?? [] });
	}
}
