import { CdkVirtualScrollViewport, ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
	selector: 'addiction-grid',
	standalone: true,
	imports: [CommonModule, ScrollingModule, TranslateModule],
	templateUrl: './grid.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GridComponent<T> {
	private maxPages = 0;

	@Input() headerTemplate?: TemplateRef<unknown>;
	@Input() cardTemplate?: TemplateRef<unknown>;
	@Input() noItemsTemplate?: TemplateRef<unknown>;
	@Input({ required: true }) set data(data: { items: T[][]; pages?: number[]; totalItems?: number }) {
		if (data.totalItems === -1) {
			this._itemsRows = [Array.from({ length: this._columns })];
			this.maxPages = 0;
			return;
		}
		if (!data || !data.pages?.length || data.items?.length !== data.pages?.length) return;
		this._availablePages = structuredClone(data.pages ?? []);
		// console.log(data);
		let arrayLength = data.totalItems;
		this.maxPages = !arrayLength ? 0 : Math.floor(arrayLength / this.pageSize);
		// console.log(this.maxPages);
		let tmpArray = [...(data.items?.flat() ?? [])];

		const tmp = this.chunkArray(tmpArray, this._columns);

		// console.log(tmp);

		this._itemsRows = tmp;
	}

	@Input() set columns(columns: number) {
		// console.log(columns);
		this._columns = columns;
		if (this._itemsRows.length) {
			this._itemsRows = this.chunkArray(this._itemsRows.flat(), this._columns);
		}
		this.loadNextPage();
	}
	@Input({ required: true }) pageSize: number = 20;

	@Output() cardClick = new EventEmitter<T>();
	@Output() pageChanges = new EventEmitter<number[]>();

	@ViewChild(CdkVirtualScrollViewport) scrollViewPort?: CdkVirtualScrollViewport;

	_availablePages: number[] = [];
	_itemsRows: T[][] = [[]];
	_columns = 6;

	get allLoaded() {
		return this._availablePages[this._availablePages.length - 1] >= this.maxPages;
	}

	chunkArray(array: T[], size: number): T[][] {
		const result = [];
		for (let i = 0; i < array.length; i += size) {
			result.push(array.slice(i, i + size).filter((item) => item !== undefined));
		}
		return result.filter((item) => item.length > 0);
	}

	loadNextPage() {
		// console.log(this._availablePages);
		const last = this._availablePages[this._availablePages.length - 1] ?? -1;
		// console.log(last);
		if (last < this.maxPages) {
			this._availablePages = [...this._availablePages, last + 1];
			this._itemsRows.push(Array.from({ length: this._columns }));
			this.pageChanges.emit(this._availablePages);
		}
	}

	trackBy(i: number) {
		return i;
	}
}
