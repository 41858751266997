<form class="form-group-container" [formGroup]="formGroup" *ngIf="formGroup">
	<div class="title-fields-container">
	<h2 *ngIf="label">{{ label }} <span *ngIf="hint" class="tooltip" [matTooltip]="hint">hint</span></h2>
	</div>
	<div class="field-container ratio-50">
		<addiction-input [readonly]="readonly" label="{{ 'PHONE.NUMBER' | translate }}" formControlName="phoneNumber"></addiction-input>
	</div>
	<div class="field-container ratio-50">
		<addiction-input [readonly]="readonly" label="{{ 'PHONE.TEXT' | translate }}" formControlName="phoneText"></addiction-input>
	</div>
</form>
